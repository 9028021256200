import { IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-enterprise';
import { QueryMarketplaceConfig, serviceMarketplace } from '@/connect/services/marketplace';
import { Side } from '@/connect/gen/consts/commonconsts_pb';
import { QueryMarketplaceFilter } from '@/connect/gen/modules/apiengine/services/venue/venue_pb';
import { OmsOrderType } from '@/connect/gen/consts/omsconsts_pb';
import { useStoreSecurities } from '@/store/store-securities';
import { useStoreAgreements } from '@/store/store-agreements';
import { Security } from '@/modules/common/models/security';

interface DataSourceConfig {
  side: Side;
  ordersPageLimit: number;
  selectedSecurity: Security | null;
}

export function createDataSource(config: DataSourceConfig): IServerSideDatasource {
  return {
    async getRows(params) {
      const queryParams = {
        tradeIntention: config.side,
        borrowFilter: getFilter('borrow', params, config.selectedSecurity),
        lendFilter: getFilter('lend', params, config.selectedSecurity),
      };

      if (params.request.groupKeys.length === 0) {
        await fetchGroups(params, queryParams);
      } else {
        await fetchItems(params, queryParams, config.ordersPageLimit);
      }
    },
  };
}

async function fetchGroups(params: IServerSideGetRowsParams, queryParams: QueryMarketplaceConfig) {
  try {
    const response = await serviceMarketplace.querySecurities(queryParams);
    if (!response.success) throw new Error('Failed to fetch securities');

    const rowData = Object.values(response.data.instruments).sort((a, b) =>
      a.ticker.localeCompare(b.ticker)
    );

    params.success({ rowData, rowCount: rowData.length });
  } catch (e) {
    params.fail();
  }
}

async function fetchItems(
  params: IServerSideGetRowsParams,
  queryParams: QueryMarketplaceConfig,
  ordersPageLimit: number
) {
  try {
    const cusip = params.request.groupKeys[0];
    queryParams.lendFilter.instruments = [cusip];
    queryParams.borrowFilter.instruments = [cusip];

    const startRow = params.request.startRow ?? 0;
    const endRow = params.request.endRow ?? ordersPageLimit;
    const pageLimit = endRow - startRow;
    const pageNumber = Math.floor(startRow / pageLimit) + 1;

    const response = await serviceMarketplace.queryMarketplace({
      ...queryParams,
      pageNumber,
      pageLimit,
    });
    if (!response.success) throw new Error('Failed to fetch marketplace');

    useStoreSecurities().addSecurities(response.data.instruments);
    useStoreAgreements().addAgreements(response.data.agreements);

    const rowData = response.data.items;
    const rowCount = rowData.length < pageLimit ? startRow + rowData.length : undefined;
    // "rowCount: undefined" indicates to AgGrid there are more rows to fetch
    params.success({ rowData, rowCount });
  } catch (e) {
    params.fail();
  }
}

function getFilter(
  direction: 'borrow' | 'lend',
  params: IServerSideGetRowsParams,
  selectedSecurity: Security | null
) {
  return {
    orderType: getOrderTypeFilter(params, direction),
    instruments: selectedSecurity ? [selectedSecurity.cusip] : undefined,
    ...getQuantityFilter(params, direction),
    ...getRateFilter(params, direction),
  } as QueryMarketplaceFilter;
}

function getOrderTypeFilter(
  params: IServerSideGetRowsParams,
  direction: 'borrow' | 'lend'
): OmsOrderType | undefined {
  const values = params.request.filterModel?.[`${direction}OrderType`]?.values;
  if (!values) return undefined;
  if (values.includes('Limit')) return OmsOrderType.LIMIT;
  if (values.includes('IOI')) return OmsOrderType.IOI;
  return undefined;
}

function getQuantityFilter(params: IServerSideGetRowsParams, direction: 'borrow' | 'lend') {
  const filter = params.request.filterModel?.[`${direction}Quantity`];
  if (!filter) return undefined;
  switch (filter.type) {
    case 'equals':
      return {
        minimumQuantity: BigInt(filter.filter),
        maximumQuantity: BigInt(filter.filter),
      };
    case 'lessThan':
      return { maximumQuantity: BigInt(filter.filter) };
    case 'greaterThan':
      return { minimumQuantity: BigInt(filter.filter) };
  }
  return undefined;
}

function getRateFilter(params: IServerSideGetRowsParams, direction: 'borrow' | 'lend') {
  const filter = params.request.filterModel?.[`${direction}Rate`];
  if (!filter) return undefined;
  switch (filter.type) {
    case 'equals':
      return {
        minimumRate: filter.filter,
        maximumRate: filter.filter,
      };
    case 'lessThan':
      return { maximumRate: filter.filter };
    case 'greaterThan':
      return { minimumRate: filter.filter };
  }
  return undefined;
}
