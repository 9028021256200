// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file consts/omsconsts.proto (package aurora.core.consts.oms, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum aurora.core.consts.oms.OmsOrderStatus
 */
export enum OmsOrderStatus {
  /**
   * UNSPECIFIED_ORDER_STATUS will be set when a value is specified that is not defined in the schema
   * and should be treated as an error.
   *
   * @generated from enum value: UNSPECIFIED_ORDER_STATUS = 0;
   */
  UNSPECIFIED_ORDER_STATUS = 0,

  /**
   * @generated from enum value: OPEN = 1;
   */
  OPEN = 1,

  /**
   * @generated from enum value: FILLED = 2;
   */
  FILLED = 2,

  /**
   * @generated from enum value: CANCELED = 3;
   */
  CANCELED = 3,

  /**
   * @generated from enum value: EXPIRED = 4;
   */
  EXPIRED = 4,

  /**
   * @generated from enum value: TERMINATED = 5;
   */
  TERMINATED = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(OmsOrderStatus)
proto3.util.setEnumType(OmsOrderStatus, "aurora.core.consts.oms.OmsOrderStatus", [
  { no: 0, name: "UNSPECIFIED_ORDER_STATUS" },
  { no: 1, name: "OPEN" },
  { no: 2, name: "FILLED" },
  { no: 3, name: "CANCELED" },
  { no: 4, name: "EXPIRED" },
  { no: 5, name: "TERMINATED" },
]);

/**
 * @generated from enum aurora.core.consts.oms.OmsOrderType
 */
export enum OmsOrderType {
  /**
   * UnspecifiedOrderType will be set when a value is specified that is not defined in the schema
   * and should be treated as an error.
   *
   * @generated from enum value: UNSPECIFIED_ORDER_TYPE = 0;
   */
  UNSPECIFIED_ORDER_TYPE = 0,

  /**
   * @generated from enum value: LIMIT = 1;
   */
  LIMIT = 1,

  /**
   * @generated from enum value: MARKET = 2;
   */
  MARKET = 2,

  /**
   * @generated from enum value: IOI = 3;
   */
  IOI = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(OmsOrderType)
proto3.util.setEnumType(OmsOrderType, "aurora.core.consts.oms.OmsOrderType", [
  { no: 0, name: "UNSPECIFIED_ORDER_TYPE" },
  { no: 1, name: "LIMIT" },
  { no: 2, name: "MARKET" },
  { no: 3, name: "IOI" },
]);

/**
 * @generated from enum aurora.core.consts.oms.OmsTimeInForceType
 */
export enum OmsTimeInForceType {
  /**
   * UnspecifiedTimeInForce will be set when a value is specified that is not defined in the schema
   * and should be treated as an error.
   *
   * @generated from enum value: UNSPECIFIED_TIME_IN_FORCE = 0;
   */
  UNSPECIFIED_TIME_IN_FORCE = 0,

  /**
   * @generated from enum value: DAY = 1;
   */
  DAY = 1,

  /**
   * @generated from enum value: GOOD_TILL_CANCEL = 2;
   */
  GOOD_TILL_CANCEL = 2,

  /**
   * @generated from enum value: IMMEDIATE_OR_CANCEL = 3;
   */
  IMMEDIATE_OR_CANCEL = 3,

  /**
   * @generated from enum value: FILL_OR_KILL = 4;
   */
  FILL_OR_KILL = 4,

  /**
   * @generated from enum value: CUSTOM_EXPIRY = 5;
   */
  CUSTOM_EXPIRY = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(OmsTimeInForceType)
proto3.util.setEnumType(OmsTimeInForceType, "aurora.core.consts.oms.OmsTimeInForceType", [
  { no: 0, name: "UNSPECIFIED_TIME_IN_FORCE" },
  { no: 1, name: "DAY" },
  { no: 2, name: "GOOD_TILL_CANCEL" },
  { no: 3, name: "IMMEDIATE_OR_CANCEL" },
  { no: 4, name: "FILL_OR_KILL" },
  { no: 5, name: "CUSTOM_EXPIRY" },
]);

/**
 * @generated from enum aurora.core.consts.oms.RoutingStatus
 */
export enum RoutingStatus {
  /**
   * UnspecifiedRoutingStatus will be set when a value is specified that is not defined in the schema
   * and should be treated as an error.
   *
   * @generated from enum value: UNSPECIFIED_ROUTING_STATUS = 0;
   */
  UNSPECIFIED_ROUTING_STATUS = 0,

  /**
   * @generated from enum value: ROUTED = 1;
   */
  ROUTED = 1,

  /**
   * @generated from enum value: UNROUTED = 2;
   */
  UNROUTED = 2,

  /**
   * @generated from enum value: PENDING = 3;
   */
  PENDING = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(RoutingStatus)
proto3.util.setEnumType(RoutingStatus, "aurora.core.consts.oms.RoutingStatus", [
  { no: 0, name: "UNSPECIFIED_ROUTING_STATUS" },
  { no: 1, name: "ROUTED" },
  { no: 2, name: "UNROUTED" },
  { no: 3, name: "PENDING" },
]);

/**
 * @generated from enum aurora.core.consts.oms.QuerySortableField
 */
export enum QuerySortableField {
  /**
   * @generated from enum value: UNSPECIFIED_QUERY_ORDERS_SORTABLE_FIELD = 0;
   */
  UNSPECIFIED_QUERY_ORDERS_SORTABLE_FIELD = 0,

  /**
   * Status
   *
   * @generated from enum value: STATUS = 1;
   */
  STATUS = 1,

  /**
   * Created time
   *
   * @generated from enum value: CREATED_AT = 2;
   */
  CREATED_AT = 2,

  /**
   * Last Update time
   *
   * @generated from enum value: UPDATED_AT = 3;
   */
  UPDATED_AT = 3,

  /**
   * Side
   *
   * @generated from enum value: SIDE = 4;
   */
  SIDE = 4,

  /**
   * Staged
   *
   * @generated from enum value: ROUTING_STATUS = 5;
   */
  ROUTING_STATUS = 5,

  /**
   * Ticker
   *
   * @generated from enum value: INSTRUMENT_TICKER = 6;
   */
  INSTRUMENT_TICKER = 6,

  /**
   * Cusip
   *
   * @generated from enum value: INSTRUMENT_CUSIP = 7;
   */
  INSTRUMENT_CUSIP = 7,

  /**
   * Open Quantity
   *
   * @generated from enum value: OPEN_QUANTITY = 8;
   */
  OPEN_QUANTITY = 8,

  /**
   * Filled Quantity
   *
   * @generated from enum value: FILLED_QUANTITY = 9;
   */
  FILLED_QUANTITY = 9,

  /**
   * Total Quantity
   *
   * @generated from enum value: TOTAL_QUANTITY = 10;
   */
  TOTAL_QUANTITY = 10,

  /**
   * Rate
   *
   * @generated from enum value: RATE = 11;
   */
  RATE = 11,

  /**
   * Average Execution Rate
   *
   * @generated from enum value: AVG_EXEC_RATE = 12;
   */
  AVG_EXEC_RATE = 12,

  /**
   * Order Type
   *
   * @generated from enum value: TYPE = 13;
   */
  TYPE = 13,

  /**
   * Time In Force
   *
   * @generated from enum value: TIME_IN_FORCE = 14;
   */
  TIME_IN_FORCE = 14,

  /**
   * Minimum Quantity
   *
   * @generated from enum value: MIN_QUANTITY = 15;
   */
  MIN_QUANTITY = 15,

  /**
   * Company Name of the owner
   *
   * @generated from enum value: COMPANY_NAME = 17;
   */
  COMPANY_NAME = 17,
}
// Retrieve enum metadata with: proto3.getEnumType(QuerySortableField)
proto3.util.setEnumType(QuerySortableField, "aurora.core.consts.oms.QuerySortableField", [
  { no: 0, name: "UNSPECIFIED_QUERY_ORDERS_SORTABLE_FIELD" },
  { no: 1, name: "STATUS" },
  { no: 2, name: "CREATED_AT" },
  { no: 3, name: "UPDATED_AT" },
  { no: 4, name: "SIDE" },
  { no: 5, name: "ROUTING_STATUS" },
  { no: 6, name: "INSTRUMENT_TICKER" },
  { no: 7, name: "INSTRUMENT_CUSIP" },
  { no: 8, name: "OPEN_QUANTITY" },
  { no: 9, name: "FILLED_QUANTITY" },
  { no: 10, name: "TOTAL_QUANTITY" },
  { no: 11, name: "RATE" },
  { no: 12, name: "AVG_EXEC_RATE" },
  { no: 13, name: "TYPE" },
  { no: 14, name: "TIME_IN_FORCE" },
  { no: 15, name: "MIN_QUANTITY" },
  { no: 17, name: "COMPANY_NAME" },
]);

