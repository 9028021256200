import { ConnectResult, Side, createPromiseClient } from '@/connect';
import { OmsService } from '@/connect/gen/modules/apiengine/services/oms/oms_connect';
import { VenueService } from '@/connect/gen/modules/apiengine/services/venue/venue_connect';
import {
  QueryExecutionRecordsResponse,
  QueryOrdersFilter,
  QueryOrdersResponse,
  QueryOrdersSort,
} from '@/connect/gen/modules/apiengine/services/oms/oms_pb';
import { QuerySortableField } from '@/connect/gen/consts/omsconsts_pb';
import { SortOrder } from '@/connect/gen/consts/queryconsts_pb';
import { SortModelItem } from 'ag-grid-enterprise';
import { Order } from '@/connect/gen/modules/apiengine/services/oms/oms_pb';
import {
  QueryMarketplaceFilter,
  QueryMarketplaceInstrumentsResponse,
  QueryMarketplaceResponse,
} from '../gen/modules/apiengine/services/venue/venue_pb';

export interface QueryMarketplaceConfig {
  lendFilter: QueryMarketplaceFilter;
  borrowFilter: QueryMarketplaceFilter;
  tradeIntention: Side;
}

export interface QueryMarketplaceConfigWithPagination extends QueryMarketplaceConfig {
  pageNumber: number;
  pageLimit: number;
}

export const serviceMarketplace = new (class {
  public readonly omsPromiseClient = createPromiseClient(OmsService);
  public readonly venuePromiseClient = createPromiseClient(VenueService);

  public async queryOrders(config: {
    page: number;
    pageSize: number;
    sort: SortModelItem;
    filter: QueryOrdersFilter;
    signal: AbortSignal;
  }): Promise<ConnectResult<QueryOrdersResponse>> {
    const sortFields: Partial<Record<keyof Order | 'security.ticker', QuerySortableField>> = {
      rate: QuerySortableField.RATE,
      side: QuerySortableField.SIDE,
      openQuantity: QuerySortableField.OPEN_QUANTITY,
      quantity: QuerySortableField.TOTAL_QUANTITY,
      minQuantity: QuerySortableField.MIN_QUANTITY,
      companyId: QuerySortableField.COMPANY_NAME,
      cusip: QuerySortableField.INSTRUMENT_CUSIP,
      ['security.ticker']: QuerySortableField.INSTRUMENT_TICKER,
      timeInForceType: QuerySortableField.TIME_IN_FORCE,
      orderType: QuerySortableField.TYPE,
      routingStatus: QuerySortableField.ROUTING_STATUS,
      orderStatus: QuerySortableField.STATUS,
      createdAt: QuerySortableField.CREATED_AT,
      updatedAt: QuerySortableField.UPDATED_AT,
      totalExecQty: QuerySortableField.FILLED_QUANTITY,
      avgExecRate: QuerySortableField.AVG_EXEC_RATE,
    };

    const field = config.sort.colId;
    const order = config.sort.sort === 'asc' ? SortOrder.ASC : SortOrder.DESC;
    const sorts =
      field in sortFields ? [new QueryOrdersSort({ field: sortFields[field], order })] : [];
    const query = {
      filter: config.filter,
      sorts,
      pageNumber: config.page,
      pageLimit: config.pageSize,
    };

    return this.omsPromiseClient.queryOrders(query);
  }

  public async querySecurities(
    config: QueryMarketplaceConfig
  ): Promise<ConnectResult<QueryMarketplaceInstrumentsResponse>> {
    return this.venuePromiseClient.queryMarketplaceInstruments(config);
  }

  public async queryMarketplace(
    config: QueryMarketplaceConfigWithPagination
  ): Promise<ConnectResult<QueryMarketplaceResponse>> {
    return this.venuePromiseClient.queryMarketplace(config);
  }

  public async queryExecutions(config: {
    companyId: string;
    pageNumber: number;
    pageLimit: number;
    signal?: AbortSignal;
  }): Promise<ConnectResult<QueryExecutionRecordsResponse>> {
    const query = {
      // server-side filtering and sorting not yet implemented
      companyId: config.companyId,
      pageNumber: config.pageNumber,
      pageLimit: config.pageLimit,
    };
    return this.omsPromiseClient.queryExecutionRecords(query);
  }
})();
