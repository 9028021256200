// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file modules/apiengine/services/oms/oms.proto (package aurora.core.apiengine.omsv1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { Side } from "../../../../consts/commonconsts_pb.js";
import { OmsOrderStatus, OmsOrderType, OmsTimeInForceType, QuerySortableField, RoutingStatus } from "../../../../consts/omsconsts_pb.js";
import { PbAgreementInfo, PbCompanyInfo, PbDate, PbDecimal, PbInstrument, PbInstrumentInfo, PbRateDetails, PbUserInfo } from "../../../../common/protos/types_pb.js";
import { SortOrder } from "../../../../consts/queryconsts_pb.js";
import { SettlementType } from "../../../../consts/settlementconsts_pb.js";
import { RoundingRule } from "../../../../consts/loanconsts_pb.js";

/**
 * @generated from enum aurora.core.apiengine.omsv1.QueryExecutionRecordsSortableField
 */
export enum QueryExecutionRecordsSortableField {
  /**
   * @generated from enum value: UNSPECIFIED_QUERY_EXECUTION_RECORDS_SORTABLE_FIELD = 0;
   */
  UNSPECIFIED_QUERY_EXECUTION_RECORDS_SORTABLE_FIELD = 0,

  /**
   * Instrument (CUSIP or Ticker)
   *
   * @generated from enum value: INSTRUMENT = 1;
   */
  INSTRUMENT = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(QueryExecutionRecordsSortableField)
proto3.util.setEnumType(QueryExecutionRecordsSortableField, "aurora.core.apiengine.omsv1.QueryExecutionRecordsSortableField", [
  { no: 0, name: "UNSPECIFIED_QUERY_EXECUTION_RECORDS_SORTABLE_FIELD" },
  { no: 1, name: "INSTRUMENT" },
]);

/**
 * @generated from enum aurora.core.apiengine.omsv1.CollateralType
 */
export enum CollateralType {
  /**
   * @generated from enum value: UNSPECIFIED_COLLATERAL_TYPE = 0;
   */
  UNSPECIFIED_COLLATERAL_TYPE = 0,

  /**
   * @generated from enum value: CASH = 1;
   */
  CASH = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(CollateralType)
proto3.util.setEnumType(CollateralType, "aurora.core.apiengine.omsv1.CollateralType", [
  { no: 0, name: "UNSPECIFIED_COLLATERAL_TYPE" },
  { no: 1, name: "CASH" },
]);

/**
 * @generated from message aurora.core.apiengine.omsv1.CreateOrderRequest
 */
export class CreateOrderRequest extends Message<CreateOrderRequest> {
  /**
   * Unique order identifier assigned by Aurora user
   *
   * @generated from field: string client_order_ref = 1;
   */
  clientOrderRef = "";

  /**
   * Cusip or Ticker of instrument
   *
   * @generated from field: string instrument = 2;
   */
  instrument = "";

  /**
   * Side of order (lender or borrower)
   *
   * @generated from field: aurora.core.consts.common.Side side = 3;
   */
  side = Side.BORROWER;

  /**
   * Type of order (limit or market)
   *
   * @generated from field: aurora.core.consts.oms.OmsOrderType order_type = 4;
   */
  orderType = OmsOrderType.UNSPECIFIED_ORDER_TYPE;

  /**
   * Quantity of shares
   *
   * @generated from field: int64 quantity = 5;
   */
  quantity = protoInt64.zero;

  /**
   * Minimum quantity of shares per execution - optional, defaults to 100
   *
   * @generated from field: optional int64 min_quantity = 6;
   */
  minQuantity?: bigint;

  /**
   * Time In Force type (e.g. day, gtc, fok, ioc) - optional
   *
   * @generated from field: optional aurora.core.consts.oms.OmsTimeInForceType time_in_force_type = 8;
   */
  timeInForceType?: OmsTimeInForceType;

  /**
   * Loan rate - optional
   *
   * @generated from field: optional string rate = 9;
   */
  rate?: string;

  /**
   * Agreement IDs - optional
   *
   * @generated from field: repeated string agreement_ids = 12;
   */
  agreementIds: string[] = [];

  /**
   * Staged order (default: false) - optional
   *
   * @generated from field: optional bool is_staged = 13;
   */
  isStaged?: boolean;

  /**
   * Anonymous order (default: false) - optional
   *
   * @generated from field: optional bool anonymous = 14;
   */
  anonymous?: boolean;

  constructor(data?: PartialMessage<CreateOrderRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.CreateOrderRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "client_order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "instrument", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "side", kind: "enum", T: proto3.getEnumType(Side) },
    { no: 4, name: "order_type", kind: "enum", T: proto3.getEnumType(OmsOrderType) },
    { no: 5, name: "quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "min_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 8, name: "time_in_force_type", kind: "enum", T: proto3.getEnumType(OmsTimeInForceType), opt: true },
    { no: 9, name: "rate", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 12, name: "agreement_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 13, name: "is_staged", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 14, name: "anonymous", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateOrderRequest {
    return new CreateOrderRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateOrderRequest {
    return new CreateOrderRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateOrderRequest {
    return new CreateOrderRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateOrderRequest | PlainMessage<CreateOrderRequest> | undefined, b: CreateOrderRequest | PlainMessage<CreateOrderRequest> | undefined): boolean {
    return proto3.util.equals(CreateOrderRequest, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.CreateOrdersRequest
 */
export class CreateOrdersRequest extends Message<CreateOrdersRequest> {
  /**
   * List of order details to create in batch
   *
   * @generated from field: repeated aurora.core.apiengine.omsv1.CreateOrderRequest requests = 1;
   */
  requests: CreateOrderRequest[] = [];

  constructor(data?: PartialMessage<CreateOrdersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.CreateOrdersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "requests", kind: "message", T: CreateOrderRequest, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateOrdersRequest {
    return new CreateOrdersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateOrdersRequest {
    return new CreateOrdersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateOrdersRequest {
    return new CreateOrdersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateOrdersRequest | PlainMessage<CreateOrdersRequest> | undefined, b: CreateOrdersRequest | PlainMessage<CreateOrdersRequest> | undefined): boolean {
    return proto3.util.equals(CreateOrdersRequest, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.CreateOrderResponse
 */
export class CreateOrderResponse extends Message<CreateOrderResponse> {
  /**
   * Unique identifier of the created order
   *
   * @generated from field: string order_ref = 1;
   */
  orderRef = "";

  /**
   * Unique order identifier assigned by Aurora user
   *
   * @generated from field: string client_order_ref = 2;
   */
  clientOrderRef = "";

  constructor(data?: PartialMessage<CreateOrderResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.CreateOrderResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "client_order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateOrderResponse {
    return new CreateOrderResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateOrderResponse {
    return new CreateOrderResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateOrderResponse {
    return new CreateOrderResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateOrderResponse | PlainMessage<CreateOrderResponse> | undefined, b: CreateOrderResponse | PlainMessage<CreateOrderResponse> | undefined): boolean {
    return proto3.util.equals(CreateOrderResponse, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.CreateOrdersResponse
 */
export class CreateOrdersResponse extends Message<CreateOrdersResponse> {
  /**
   * List of unique identifiers for the created orders
   *
   * @generated from field: repeated aurora.core.apiengine.omsv1.CreateOrderResponse responses = 1;
   */
  responses: CreateOrderResponse[] = [];

  /**
   * @generated from field: repeated aurora.core.apiengine.omsv1.CreateOrderError errors = 2;
   */
  errors: CreateOrderError[] = [];

  constructor(data?: PartialMessage<CreateOrdersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.CreateOrdersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "responses", kind: "message", T: CreateOrderResponse, repeated: true },
    { no: 2, name: "errors", kind: "message", T: CreateOrderError, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateOrdersResponse {
    return new CreateOrdersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateOrdersResponse {
    return new CreateOrdersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateOrdersResponse {
    return new CreateOrdersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateOrdersResponse | PlainMessage<CreateOrdersResponse> | undefined, b: CreateOrdersResponse | PlainMessage<CreateOrdersResponse> | undefined): boolean {
    return proto3.util.equals(CreateOrdersResponse, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.CreateOrderError
 */
export class CreateOrderError extends Message<CreateOrderError> {
  /**
   * Unique order identifier assigned by Aurora user
   *
   * @generated from field: string client_order_ref = 1;
   */
  clientOrderRef = "";

  /**
   * Error when attempting to create the order
   *
   * @generated from field: string error_message = 2;
   */
  errorMessage = "";

  constructor(data?: PartialMessage<CreateOrderError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.CreateOrderError";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "client_order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "error_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateOrderError {
    return new CreateOrderError().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateOrderError {
    return new CreateOrderError().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateOrderError {
    return new CreateOrderError().fromJsonString(jsonString, options);
  }

  static equals(a: CreateOrderError | PlainMessage<CreateOrderError> | undefined, b: CreateOrderError | PlainMessage<CreateOrderError> | undefined): boolean {
    return proto3.util.equals(CreateOrderError, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.ModifyOrderRequest
 */
export class ModifyOrderRequest extends Message<ModifyOrderRequest> {
  /**
   * Unique identifier of the order to be modified
   *
   * @generated from field: string order_ref = 1;
   */
  orderRef = "";

  /**
   * Last known Unique order identifier used to create or modify the order assigned by Aurora user
   *
   * @generated from field: string old_client_order_ref = 2;
   */
  oldClientOrderRef = "";

  /**
   * New Unique order identifier assigned by Aurora user
   *
   * @generated from field: string client_order_ref = 3;
   */
  clientOrderRef = "";

  /**
   * Quantity of shares
   *
   * @generated from field: int64 quantity = 4;
   */
  quantity = protoInt64.zero;

  /**
   * Minimum quantity of shares per execution - optional, defaults to 100
   *
   * @generated from field: optional int64 min_quantity = 5;
   */
  minQuantity?: bigint;

  /**
   * Loan rate - optional for 'market' orders
   *
   * @generated from field: optional string rate = 6;
   */
  rate?: string;

  /**
   * Agreement IDs - optional
   *
   * @generated from field: repeated string agreement_ids = 7;
   */
  agreementIds: string[] = [];

  /**
   * Staged order (default: false) - optional
   *
   * @generated from field: optional bool is_staged = 8;
   */
  isStaged?: boolean;

  constructor(data?: PartialMessage<ModifyOrderRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.ModifyOrderRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "old_client_order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "client_order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "min_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 6, name: "rate", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "agreement_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "is_staged", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ModifyOrderRequest {
    return new ModifyOrderRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ModifyOrderRequest {
    return new ModifyOrderRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ModifyOrderRequest {
    return new ModifyOrderRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ModifyOrderRequest | PlainMessage<ModifyOrderRequest> | undefined, b: ModifyOrderRequest | PlainMessage<ModifyOrderRequest> | undefined): boolean {
    return proto3.util.equals(ModifyOrderRequest, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.ModifyOrdersRequest
 */
export class ModifyOrdersRequest extends Message<ModifyOrdersRequest> {
  /**
   * List of orders to modify
   *
   * @generated from field: repeated aurora.core.apiengine.omsv1.ModifyOrderRequest requests = 1;
   */
  requests: ModifyOrderRequest[] = [];

  constructor(data?: PartialMessage<ModifyOrdersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.ModifyOrdersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "requests", kind: "message", T: ModifyOrderRequest, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ModifyOrdersRequest {
    return new ModifyOrdersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ModifyOrdersRequest {
    return new ModifyOrdersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ModifyOrdersRequest {
    return new ModifyOrdersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ModifyOrdersRequest | PlainMessage<ModifyOrdersRequest> | undefined, b: ModifyOrdersRequest | PlainMessage<ModifyOrdersRequest> | undefined): boolean {
    return proto3.util.equals(ModifyOrdersRequest, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.ModifyOrderResponse
 */
export class ModifyOrderResponse extends Message<ModifyOrderResponse> {
  /**
   * Unique identifier of the modified order
   *
   * @generated from field: string order_ref = 1;
   */
  orderRef = "";

  /**
   * New Unique order identifier assigned by Aurora user
   *
   * @generated from field: string client_order_ref = 2;
   */
  clientOrderRef = "";

  constructor(data?: PartialMessage<ModifyOrderResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.ModifyOrderResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "client_order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ModifyOrderResponse {
    return new ModifyOrderResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ModifyOrderResponse {
    return new ModifyOrderResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ModifyOrderResponse {
    return new ModifyOrderResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ModifyOrderResponse | PlainMessage<ModifyOrderResponse> | undefined, b: ModifyOrderResponse | PlainMessage<ModifyOrderResponse> | undefined): boolean {
    return proto3.util.equals(ModifyOrderResponse, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.ModifyOrdersResponse
 */
export class ModifyOrdersResponse extends Message<ModifyOrdersResponse> {
  /**
   * List of unique identifiers for the created orders
   *
   * @generated from field: repeated aurora.core.apiengine.omsv1.ModifyOrderResponse responses = 1;
   */
  responses: ModifyOrderResponse[] = [];

  /**
   * @generated from field: repeated aurora.core.apiengine.omsv1.ModifyOrderError errors = 2;
   */
  errors: ModifyOrderError[] = [];

  constructor(data?: PartialMessage<ModifyOrdersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.ModifyOrdersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "responses", kind: "message", T: ModifyOrderResponse, repeated: true },
    { no: 2, name: "errors", kind: "message", T: ModifyOrderError, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ModifyOrdersResponse {
    return new ModifyOrdersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ModifyOrdersResponse {
    return new ModifyOrdersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ModifyOrdersResponse {
    return new ModifyOrdersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ModifyOrdersResponse | PlainMessage<ModifyOrdersResponse> | undefined, b: ModifyOrdersResponse | PlainMessage<ModifyOrdersResponse> | undefined): boolean {
    return proto3.util.equals(ModifyOrdersResponse, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.ModifyOrderError
 */
export class ModifyOrderError extends Message<ModifyOrderError> {
  /**
   * Unique identifier of the order to be modified
   *
   * @generated from field: string order_ref = 1;
   */
  orderRef = "";

  /**
   * Unique identifier of the modified order assigned by Aurora user
   *
   * @generated from field: string client_order_ref = 2;
   */
  clientOrderRef = "";

  /**
   * Error when attempting to modify the order
   *
   * @generated from field: string error_message = 3;
   */
  errorMessage = "";

  constructor(data?: PartialMessage<ModifyOrderError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.ModifyOrderError";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "client_order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "error_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ModifyOrderError {
    return new ModifyOrderError().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ModifyOrderError {
    return new ModifyOrderError().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ModifyOrderError {
    return new ModifyOrderError().fromJsonString(jsonString, options);
  }

  static equals(a: ModifyOrderError | PlainMessage<ModifyOrderError> | undefined, b: ModifyOrderError | PlainMessage<ModifyOrderError> | undefined): boolean {
    return proto3.util.equals(ModifyOrderError, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.CancelOrderRequest
 */
export class CancelOrderRequest extends Message<CancelOrderRequest> {
  /**
   * New Unique request identifier assigned by Aurora user
   *
   * @generated from field: string request_id = 1;
   */
  requestId = "";

  /**
   * Unique identifier of the order to be canceled - optional
   *
   * @generated from field: optional string order_ref = 2;
   */
  orderRef?: string;

  /**
   * Last known Unique order identifier used to create or modify the order assigned by Aurora user
   *
   * @generated from field: string client_order_ref = 3;
   */
  clientOrderRef = "";

  /**
   * Cusip or Ticker of instrument
   *
   * @generated from field: string instrument = 4;
   */
  instrument = "";

  /**
   * Side of order (lender or borrower)
   *
   * @generated from field: aurora.core.consts.common.Side side = 5;
   */
  side = Side.BORROWER;

  constructor(data?: PartialMessage<CancelOrderRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.CancelOrderRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "client_order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "instrument", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "side", kind: "enum", T: proto3.getEnumType(Side) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CancelOrderRequest {
    return new CancelOrderRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CancelOrderRequest {
    return new CancelOrderRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CancelOrderRequest {
    return new CancelOrderRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CancelOrderRequest | PlainMessage<CancelOrderRequest> | undefined, b: CancelOrderRequest | PlainMessage<CancelOrderRequest> | undefined): boolean {
    return proto3.util.equals(CancelOrderRequest, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.CancelOrdersRequest
 */
export class CancelOrdersRequest extends Message<CancelOrdersRequest> {
  /**
   * List of orders to cancel
   *
   * @generated from field: repeated aurora.core.apiengine.omsv1.CancelOrderRequest requests = 1;
   */
  requests: CancelOrderRequest[] = [];

  constructor(data?: PartialMessage<CancelOrdersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.CancelOrdersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "requests", kind: "message", T: CancelOrderRequest, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CancelOrdersRequest {
    return new CancelOrdersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CancelOrdersRequest {
    return new CancelOrdersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CancelOrdersRequest {
    return new CancelOrdersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CancelOrdersRequest | PlainMessage<CancelOrdersRequest> | undefined, b: CancelOrdersRequest | PlainMessage<CancelOrdersRequest> | undefined): boolean {
    return proto3.util.equals(CancelOrdersRequest, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.CancelOrderResponse
 */
export class CancelOrderResponse extends Message<CancelOrderResponse> {
  /**
   * Unique identifier of the canceled order
   *
   * @generated from field: string order_ref = 1;
   */
  orderRef = "";

  /**
   * Last known Unique order identifier used to create or modify the order assigned by Aurora user
   *
   * @generated from field: string client_order_ref = 2;
   */
  clientOrderRef = "";

  /**
   * Status of the order in Aurora
   *
   * @generated from field: aurora.core.consts.oms.OmsOrderStatus order_status = 3;
   */
  orderStatus = OmsOrderStatus.UNSPECIFIED_ORDER_STATUS;

  constructor(data?: PartialMessage<CancelOrderResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.CancelOrderResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "client_order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "order_status", kind: "enum", T: proto3.getEnumType(OmsOrderStatus) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CancelOrderResponse {
    return new CancelOrderResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CancelOrderResponse {
    return new CancelOrderResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CancelOrderResponse {
    return new CancelOrderResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CancelOrderResponse | PlainMessage<CancelOrderResponse> | undefined, b: CancelOrderResponse | PlainMessage<CancelOrderResponse> | undefined): boolean {
    return proto3.util.equals(CancelOrderResponse, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.CancelOrdersResponse
 */
export class CancelOrdersResponse extends Message<CancelOrdersResponse> {
  /**
   * Response to each of the canceled orders
   *
   * @generated from field: repeated aurora.core.apiengine.omsv1.CancelOrderResponse responses = 1;
   */
  responses: CancelOrderResponse[] = [];

  /**
   * @generated from field: repeated aurora.core.apiengine.omsv1.CancelOrderError errors = 2;
   */
  errors: CancelOrderError[] = [];

  constructor(data?: PartialMessage<CancelOrdersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.CancelOrdersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "responses", kind: "message", T: CancelOrderResponse, repeated: true },
    { no: 2, name: "errors", kind: "message", T: CancelOrderError, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CancelOrdersResponse {
    return new CancelOrdersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CancelOrdersResponse {
    return new CancelOrdersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CancelOrdersResponse {
    return new CancelOrdersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CancelOrdersResponse | PlainMessage<CancelOrdersResponse> | undefined, b: CancelOrdersResponse | PlainMessage<CancelOrdersResponse> | undefined): boolean {
    return proto3.util.equals(CancelOrdersResponse, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.CancelOrderError
 */
export class CancelOrderError extends Message<CancelOrderError> {
  /**
   * Unique identifier of the order to be canceled - optional
   *
   * @generated from field: string order_ref = 1;
   */
  orderRef = "";

  /**
   * Unique identifier of the canceled order assigned by Aurora user
   *
   * @generated from field: string client_order_ref = 2;
   */
  clientOrderRef = "";

  /**
   * Error when attempting to cancel the order
   *
   * @generated from field: string error_message = 3;
   */
  errorMessage = "";

  constructor(data?: PartialMessage<CancelOrderError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.CancelOrderError";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "client_order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "error_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CancelOrderError {
    return new CancelOrderError().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CancelOrderError {
    return new CancelOrderError().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CancelOrderError {
    return new CancelOrderError().fromJsonString(jsonString, options);
  }

  static equals(a: CancelOrderError | PlainMessage<CancelOrderError> | undefined, b: CancelOrderError | PlainMessage<CancelOrderError> | undefined): boolean {
    return proto3.util.equals(CancelOrderError, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.OrderDetails
 */
export class OrderDetails extends Message<OrderDetails> {
  /**
   * Unique identifier of the order to get
   *
   * @generated from field: string order_ref = 2;
   */
  orderRef = "";

  /**
   * Last known Unique order identifier used to create or modify the order assigned by Aurora user
   *
   * @generated from field: string client_order_ref = 3;
   */
  clientOrderRef = "";

  /**
   * Cusip or Ticker of instrument
   *
   * @generated from field: aurora.core.common.protos.PbInstrument instrument = 4;
   */
  instrument?: PbInstrument;

  /**
   * Side of order (lender or borrower)
   *
   * @generated from field: aurora.core.consts.common.Side side = 5;
   */
  side = Side.BORROWER;

  /**
   * Status of the order in Aurora
   *
   * @generated from field: aurora.core.consts.oms.OmsOrderStatus order_status = 6;
   */
  orderStatus = OmsOrderStatus.UNSPECIFIED_ORDER_STATUS;

  /**
   * Type of order (limit or market)
   *
   * @generated from field: aurora.core.consts.oms.OmsOrderType order_type = 8;
   */
  orderType = OmsOrderType.UNSPECIFIED_ORDER_TYPE;

  /**
   * Time In Force type (e.g. day, gtc, fok, ioc)
   *
   * @generated from field: aurora.core.consts.oms.OmsTimeInForceType time_in_force_type = 9;
   */
  timeInForceType = OmsTimeInForceType.UNSPECIFIED_TIME_IN_FORCE;

  /**
   * Staged order
   *
   * @generated from field: bool is_staged = 10;
   */
  isStaged = false;

  /**
   * Routing of the order
   *
   * @generated from field: aurora.core.consts.oms.RoutingStatus routing_status = 11;
   */
  routingStatus = RoutingStatus.UNSPECIFIED_ROUTING_STATUS;

  /**
   * Quantity of shares
   *
   * @generated from field: int64 quantity = 12;
   */
  quantity = protoInt64.zero;

  /**
   * Minimum quantity of shares per execution
   *
   * @generated from field: int64 min_quantity = 13;
   */
  minQuantity = protoInt64.zero;

  /**
   * Anonymous order
   *
   * @generated from field: bool anonymous = 16;
   */
  anonymous = false;

  /**
   * Loan rate
   *
   * @generated from field: string rate = 17;
   */
  rate = "";

  /**
   * Agreement IDs
   *
   * @generated from field: repeated string agreement_ids = 18;
   */
  agreementIds: string[] = [];

  /**
   * Total quantity still available for matching
   *
   * @generated from field: int64 open_quantity = 20;
   */
  openQuantity = protoInt64.zero;

  /**
   * Total executed quantity so far
   *
   * @generated from field: int64 total_exec_qty = 21;
   */
  totalExecQty = protoInt64.zero;

  /**
   * Average rate of executed quantity so far
   *
   * @generated from field: string avg_exec_rate = 22;
   */
  avgExecRate = "";

  /**
   * Order created at
   *
   * @generated from field: google.protobuf.Timestamp created_at = 23;
   */
  createdAt?: Timestamp;

  /**
   * Order was last changed at
   *
   * @generated from field: google.protobuf.Timestamp updated_at = 24;
   */
  updatedAt?: Timestamp;

  /**
   * Owner of the order
   *
   * @generated from field: aurora.core.common.protos.PbCompanyInfo company_info = 25;
   */
  companyInfo?: PbCompanyInfo;

  constructor(data?: PartialMessage<OrderDetails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.OrderDetails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "client_order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "instrument", kind: "message", T: PbInstrument },
    { no: 5, name: "side", kind: "enum", T: proto3.getEnumType(Side) },
    { no: 6, name: "order_status", kind: "enum", T: proto3.getEnumType(OmsOrderStatus) },
    { no: 8, name: "order_type", kind: "enum", T: proto3.getEnumType(OmsOrderType) },
    { no: 9, name: "time_in_force_type", kind: "enum", T: proto3.getEnumType(OmsTimeInForceType) },
    { no: 10, name: "is_staged", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "routing_status", kind: "enum", T: proto3.getEnumType(RoutingStatus) },
    { no: 12, name: "quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 13, name: "min_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 16, name: "anonymous", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 17, name: "rate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "agreement_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 20, name: "open_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 21, name: "total_exec_qty", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 22, name: "avg_exec_rate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 23, name: "created_at", kind: "message", T: Timestamp },
    { no: 24, name: "updated_at", kind: "message", T: Timestamp },
    { no: 25, name: "company_info", kind: "message", T: PbCompanyInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OrderDetails {
    return new OrderDetails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OrderDetails {
    return new OrderDetails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OrderDetails {
    return new OrderDetails().fromJsonString(jsonString, options);
  }

  static equals(a: OrderDetails | PlainMessage<OrderDetails> | undefined, b: OrderDetails | PlainMessage<OrderDetails> | undefined): boolean {
    return proto3.util.equals(OrderDetails, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.GetOrdersRequest
 */
export class GetOrdersRequest extends Message<GetOrdersRequest> {
  /**
   * New Unique order identifier assigned by Aurora user
   *
   * @generated from field: string request_id = 1;
   */
  requestId = "";

  /**
   * Unique identifier of the order to get - optional
   *
   * @generated from field: optional string order_id = 2;
   */
  orderId?: string;

  /**
   * Last known Unique order identifier used to create or modify the order assigned by Aurora user - optional
   *
   * @generated from field: optional string client_order_id = 3;
   */
  clientOrderId?: string;

  /**
   * Cusip or Ticker of instrument - optional
   *
   * @generated from field: optional string instrument = 4;
   */
  instrument?: string;

  /**
   * Side of order (lender or borrower) - optional
   *
   * @generated from field: optional aurora.core.consts.common.Side side = 5;
   */
  side?: Side;

  constructor(data?: PartialMessage<GetOrdersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.GetOrdersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "client_order_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "instrument", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "side", kind: "enum", T: proto3.getEnumType(Side), opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrdersRequest {
    return new GetOrdersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrdersRequest {
    return new GetOrdersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrdersRequest {
    return new GetOrdersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrdersRequest | PlainMessage<GetOrdersRequest> | undefined, b: GetOrdersRequest | PlainMessage<GetOrdersRequest> | undefined): boolean {
    return proto3.util.equals(GetOrdersRequest, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.GetOrdersResponse
 */
export class GetOrdersResponse extends Message<GetOrdersResponse> {
  /**
   * New Unique order identifier assigned by Aurora user
   *
   * @generated from field: string request_id = 1;
   */
  requestId = "";

  /**
   * Detail(s) of one or more orders returned by Aurora
   *
   * @generated from field: repeated aurora.core.apiengine.omsv1.OrderDetails orders = 2;
   */
  orders: OrderDetails[] = [];

  /**
   * Number of orders returned by Aurora
   *
   * @generated from field: int32 total_count = 3;
   */
  totalCount = 0;

  constructor(data?: PartialMessage<GetOrdersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.GetOrdersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "orders", kind: "message", T: OrderDetails, repeated: true },
    { no: 3, name: "total_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrdersResponse {
    return new GetOrdersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrdersResponse {
    return new GetOrdersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrdersResponse {
    return new GetOrdersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrdersResponse | PlainMessage<GetOrdersResponse> | undefined, b: GetOrdersResponse | PlainMessage<GetOrdersResponse> | undefined): boolean {
    return proto3.util.equals(GetOrdersResponse, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.QueryOrdersRequest
 */
export class QueryOrdersRequest extends Message<QueryOrdersRequest> {
  /**
   * New Unique identifier assigned by the user
   *
   * @generated from field: string request_id = 1;
   */
  requestId = "";

  /**
   * Filter
   *
   * @generated from field: optional aurora.core.apiengine.omsv1.QueryOrdersFilter filter = 2;
   */
  filter?: QueryOrdersFilter;

  /**
   * List of sorting options
   *
   * @generated from field: repeated aurora.core.apiengine.omsv1.QueryOrdersSort sorts = 3;
   */
  sorts: QueryOrdersSort[] = [];

  /**
   * Page number, 1 being the starting one
   *
   * @generated from field: optional int32 page_number = 4;
   */
  pageNumber?: number;

  /**
   * Number of orders per page
   *
   * @generated from field: optional int32 page_limit = 5;
   */
  pageLimit?: number;

  constructor(data?: PartialMessage<QueryOrdersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.QueryOrdersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "filter", kind: "message", T: QueryOrdersFilter, opt: true },
    { no: 3, name: "sorts", kind: "message", T: QueryOrdersSort, repeated: true },
    { no: 4, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: "page_limit", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryOrdersRequest {
    return new QueryOrdersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryOrdersRequest {
    return new QueryOrdersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryOrdersRequest {
    return new QueryOrdersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: QueryOrdersRequest | PlainMessage<QueryOrdersRequest> | undefined, b: QueryOrdersRequest | PlainMessage<QueryOrdersRequest> | undefined): boolean {
    return proto3.util.equals(QueryOrdersRequest, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.QueryOrdersFilter
 */
export class QueryOrdersFilter extends Message<QueryOrdersFilter> {
  /**
   * Include expired orders?
   *
   * @generated from field: optional bool show_all = 1;
   */
  showAll?: boolean;

  /**
   * Unique identifier of the order to get - optional
   *
   * @generated from field: optional string order_id = 2;
   */
  orderId?: string;

  /**
   * Last known Unique order identifier used to create or modify the order assigned by Aurora user - optional
   *
   * @generated from field: optional string client_order_id = 3;
   */
  clientOrderId?: string;

  /**
   * Cusip or Ticker of instrument - optional
   *
   * @generated from field: repeated string instruments = 4;
   */
  instruments: string[] = [];

  /**
   * Side of the order (lender or borrower) - optional
   *
   * @generated from field: optional aurora.core.consts.common.Side side = 5;
   */
  side?: Side;

  /**
   * Status of the order in Aurora - optional
   *
   * @generated from field: optional aurora.core.consts.oms.OmsOrderStatus order_status = 6;
   */
  orderStatus?: OmsOrderStatus;

  /**
   * Routing status of the order in Aurora - option
   *
   * @generated from field: optional aurora.core.consts.oms.RoutingStatus routing_status = 7;
   */
  routingStatus?: RoutingStatus;

  /**
   * Only return orders that are (partial) executed - optional
   *
   * @generated from field: optional bool has_executed = 8;
   */
  hasExecuted?: boolean;

  /**
   * Type of the order (Limit, Market, iO, etc) - optional
   *
   * @generated from field: optional aurora.core.consts.oms.OmsOrderType order_type = 9;
   */
  orderType?: OmsOrderType;

  /**
   * Owner of the order - optional for admins
   *
   * @generated from field: optional string company_id = 10;
   */
  companyId?: string;

  constructor(data?: PartialMessage<QueryOrdersFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.QueryOrdersFilter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "show_all", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 2, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "client_order_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "instruments", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "side", kind: "enum", T: proto3.getEnumType(Side), opt: true },
    { no: 6, name: "order_status", kind: "enum", T: proto3.getEnumType(OmsOrderStatus), opt: true },
    { no: 7, name: "routing_status", kind: "enum", T: proto3.getEnumType(RoutingStatus), opt: true },
    { no: 8, name: "has_executed", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 9, name: "order_type", kind: "enum", T: proto3.getEnumType(OmsOrderType), opt: true },
    { no: 10, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryOrdersFilter {
    return new QueryOrdersFilter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryOrdersFilter {
    return new QueryOrdersFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryOrdersFilter {
    return new QueryOrdersFilter().fromJsonString(jsonString, options);
  }

  static equals(a: QueryOrdersFilter | PlainMessage<QueryOrdersFilter> | undefined, b: QueryOrdersFilter | PlainMessage<QueryOrdersFilter> | undefined): boolean {
    return proto3.util.equals(QueryOrdersFilter, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.QueryOrdersSort
 */
export class QueryOrdersSort extends Message<QueryOrdersSort> {
  /**
   * Fields to sort on
   *
   * @generated from field: aurora.core.consts.oms.QuerySortableField field = 1;
   */
  field = QuerySortableField.UNSPECIFIED_QUERY_ORDERS_SORTABLE_FIELD;

  /**
   * Sorting order
   *
   * @generated from field: aurora.core.consts.query.SortOrder order = 2;
   */
  order = SortOrder.UNSPECIFIED_SORT_ORDER;

  constructor(data?: PartialMessage<QueryOrdersSort>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.QueryOrdersSort";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "field", kind: "enum", T: proto3.getEnumType(QuerySortableField) },
    { no: 2, name: "order", kind: "enum", T: proto3.getEnumType(SortOrder) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryOrdersSort {
    return new QueryOrdersSort().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryOrdersSort {
    return new QueryOrdersSort().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryOrdersSort {
    return new QueryOrdersSort().fromJsonString(jsonString, options);
  }

  static equals(a: QueryOrdersSort | PlainMessage<QueryOrdersSort> | undefined, b: QueryOrdersSort | PlainMessage<QueryOrdersSort> | undefined): boolean {
    return proto3.util.equals(QueryOrdersSort, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.QueryOrdersResponse
 */
export class QueryOrdersResponse extends Message<QueryOrdersResponse> {
  /**
   * Unique request identifier assigned by Aurora user
   *
   * @generated from field: string request_id = 1;
   */
  requestId = "";

  /**
   * companies, agreements and instruments referred to in the OrderDetails
   *
   * @generated from field: map<string, aurora.core.common.protos.PbCompanyInfo> companies = 2;
   */
  companies: { [key: string]: PbCompanyInfo } = {};

  /**
   * @generated from field: map<string, aurora.core.common.protos.PbInstrumentInfo> instruments = 3;
   */
  instruments: { [key: string]: PbInstrumentInfo } = {};

  /**
   * @generated from field: map<string, aurora.core.common.protos.PbAgreementInfo> agreements = 6;
   */
  agreements: { [key: string]: PbAgreementInfo } = {};

  /**
   * List of OMS orders
   *
   * @generated from field: repeated aurora.core.apiengine.omsv1.Order orders = 4;
   */
  orders: Order[] = [];

  /**
   * Total number of orders
   *
   * @generated from field: int32 total_count = 5;
   */
  totalCount = 0;

  constructor(data?: PartialMessage<QueryOrdersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.QueryOrdersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "companies", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: PbCompanyInfo} },
    { no: 3, name: "instruments", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: PbInstrumentInfo} },
    { no: 6, name: "agreements", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: PbAgreementInfo} },
    { no: 4, name: "orders", kind: "message", T: Order, repeated: true },
    { no: 5, name: "total_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryOrdersResponse {
    return new QueryOrdersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryOrdersResponse {
    return new QueryOrdersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryOrdersResponse {
    return new QueryOrdersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: QueryOrdersResponse | PlainMessage<QueryOrdersResponse> | undefined, b: QueryOrdersResponse | PlainMessage<QueryOrdersResponse> | undefined): boolean {
    return proto3.util.equals(QueryOrdersResponse, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.Order
 */
export class Order extends Message<Order> {
  /**
   * Unique identifier of the order to get
   *
   * @generated from field: string order_ref = 2;
   */
  orderRef = "";

  /**
   * Last known Unique order identifier used to create or modify the order assigned by Aurora user
   *
   * @generated from field: string client_order_ref = 3;
   */
  clientOrderRef = "";

  /**
   * Identifier of the instrument
   *
   * @generated from field: string cusip = 4;
   */
  cusip = "";

  /**
   * Side of order (lender or borrower)
   *
   * @generated from field: aurora.core.consts.common.Side side = 5;
   */
  side = Side.BORROWER;

  /**
   * Status of the order in Aurora
   *
   * @generated from field: aurora.core.consts.oms.OmsOrderStatus order_status = 6;
   */
  orderStatus = OmsOrderStatus.UNSPECIFIED_ORDER_STATUS;

  /**
   * Type of order (limit, market or IOI)
   *
   * @generated from field: aurora.core.consts.oms.OmsOrderType order_type = 8;
   */
  orderType = OmsOrderType.UNSPECIFIED_ORDER_TYPE;

  /**
   * Time In Force type (e.g. day, gtc, fok, ioc)
   *
   * @generated from field: aurora.core.consts.oms.OmsTimeInForceType time_in_force_type = 9;
   */
  timeInForceType = OmsTimeInForceType.UNSPECIFIED_TIME_IN_FORCE;

  /**
   * Order is routed, unrouted or pending
   *
   * @generated from field: aurora.core.consts.oms.RoutingStatus routing_status = 10;
   */
  routingStatus = RoutingStatus.UNSPECIFIED_ROUTING_STATUS;

  /**
   * Quantity of shares
   *
   * @generated from field: int64 quantity = 11;
   */
  quantity = protoInt64.zero;

  /**
   * Minimum quantity of shares per execution
   *
   * @generated from field: int64 min_quantity = 12;
   */
  minQuantity = protoInt64.zero;

  /**
   * Anonymous order
   *
   * @generated from field: bool anonymous = 15;
   */
  anonymous = false;

  /**
   * Requested rate
   *
   * @generated from field: string rate = 16;
   */
  rate = "";

  /**
   * Agreement ids
   *
   * @generated from field: repeated string agreement_ids = 17;
   */
  agreementIds: string[] = [];

  /**
   * Total quantity still available for matching
   *
   * @generated from field: int64 open_quantity = 18;
   */
  openQuantity = protoInt64.zero;

  /**
   * Total executed quantity so far
   *
   * @generated from field: int64 total_exec_qty = 19;
   */
  totalExecQty = protoInt64.zero;

  /**
   * Average rate of executed quantity so far
   *
   * @generated from field: string avg_exec_rate = 20;
   */
  avgExecRate = "";

  /**
   * Order created at
   *
   * @generated from field: google.protobuf.Timestamp created_at = 21;
   */
  createdAt?: Timestamp;

  /**
   * Order was last changed at
   *
   * @generated from field: google.protobuf.Timestamp updated_at = 22;
   */
  updatedAt?: Timestamp;

  /**
   * Owner of the order
   *
   * @generated from field: string company_id = 23;
   */
  companyId = "";

  constructor(data?: PartialMessage<Order>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.Order";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "client_order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "cusip", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "side", kind: "enum", T: proto3.getEnumType(Side) },
    { no: 6, name: "order_status", kind: "enum", T: proto3.getEnumType(OmsOrderStatus) },
    { no: 8, name: "order_type", kind: "enum", T: proto3.getEnumType(OmsOrderType) },
    { no: 9, name: "time_in_force_type", kind: "enum", T: proto3.getEnumType(OmsTimeInForceType) },
    { no: 10, name: "routing_status", kind: "enum", T: proto3.getEnumType(RoutingStatus) },
    { no: 11, name: "quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 12, name: "min_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 15, name: "anonymous", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 16, name: "rate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "agreement_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 18, name: "open_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 19, name: "total_exec_qty", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 20, name: "avg_exec_rate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "created_at", kind: "message", T: Timestamp },
    { no: 22, name: "updated_at", kind: "message", T: Timestamp },
    { no: 23, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Order {
    return new Order().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Order {
    return new Order().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Order {
    return new Order().fromJsonString(jsonString, options);
  }

  static equals(a: Order | PlainMessage<Order> | undefined, b: Order | PlainMessage<Order> | undefined): boolean {
    return proto3.util.equals(Order, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.CreatedOrderEvent
 */
export class CreatedOrderEvent extends Message<CreatedOrderEvent> {
  /**
   * Unique request identifier assigned to the event
   *
   * @generated from field: string request_id = 1;
   */
  requestId = "";

  /**
   * Unique identifier of the created order
   *
   * @generated from field: string order_ref = 2;
   */
  orderRef = "";

  /**
   * Details of the order
   *
   * @generated from field: aurora.core.apiengine.omsv1.OrderDetails order_details = 3;
   */
  orderDetails?: OrderDetails;

  constructor(data?: PartialMessage<CreatedOrderEvent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.CreatedOrderEvent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "order_details", kind: "message", T: OrderDetails },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatedOrderEvent {
    return new CreatedOrderEvent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatedOrderEvent {
    return new CreatedOrderEvent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatedOrderEvent {
    return new CreatedOrderEvent().fromJsonString(jsonString, options);
  }

  static equals(a: CreatedOrderEvent | PlainMessage<CreatedOrderEvent> | undefined, b: CreatedOrderEvent | PlainMessage<CreatedOrderEvent> | undefined): boolean {
    return proto3.util.equals(CreatedOrderEvent, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.ModifiedOrderEvent
 */
export class ModifiedOrderEvent extends Message<ModifiedOrderEvent> {
  /**
   * Unique request identifier assigned to the event
   *
   * @generated from field: string request_id = 1;
   */
  requestId = "";

  /**
   * Unique identifier of the modified order
   *
   * @generated from field: string order_ref = 2;
   */
  orderRef = "";

  /**
   * Details of the order
   *
   * @generated from field: aurora.core.apiengine.omsv1.OrderDetails order_details = 3;
   */
  orderDetails?: OrderDetails;

  constructor(data?: PartialMessage<ModifiedOrderEvent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.ModifiedOrderEvent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "order_details", kind: "message", T: OrderDetails },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ModifiedOrderEvent {
    return new ModifiedOrderEvent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ModifiedOrderEvent {
    return new ModifiedOrderEvent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ModifiedOrderEvent {
    return new ModifiedOrderEvent().fromJsonString(jsonString, options);
  }

  static equals(a: ModifiedOrderEvent | PlainMessage<ModifiedOrderEvent> | undefined, b: ModifiedOrderEvent | PlainMessage<ModifiedOrderEvent> | undefined): boolean {
    return proto3.util.equals(ModifiedOrderEvent, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.ExecutedOrderEvent
 */
export class ExecutedOrderEvent extends Message<ExecutedOrderEvent> {
  /**
   * Unique request identifier assigned to the event
   *
   * @generated from field: string request_id = 1;
   */
  requestId = "";

  /**
   * Unique order execution identifier assigned by Aurora system
   *
   * @generated from field: string execution_id = 2;
   */
  executionId = "";

  /**
   * Details about the order
   *
   * @generated from field: aurora.core.apiengine.omsv1.OrderDetails order_details = 3;
   */
  orderDetails?: OrderDetails;

  /**
   * Executed quantity
   *
   * @generated from field: int64 exec_quantity = 4;
   */
  execQuantity = protoInt64.zero;

  /**
   * Rate the order was executed for
   *
   * @generated from field: string exec_rate = 5;
   */
  execRate = "";

  /**
   * Execution time
   *
   * @generated from field: google.protobuf.Timestamp execution_time = 6;
   */
  executionTime?: Timestamp;

  /**
   * Aurora internal ID for the counterparty company
   *
   * @generated from field: string counterparty_id = 8;
   */
  counterpartyId = "";

  /**
   * Aurora internal ID for the agreement related to the (partially) executed order
   *
   * @generated from field: string agreement_id = 9;
   */
  agreementId = "";

  /**
   * Settlement type
   *
   * @generated from field: aurora.core.consts.settlement.SettlementType settlement_type = 10;
   */
  settlementType = SettlementType.UNSPECIFIED;

  /**
   * Rounding rule
   *
   * @generated from field: aurora.core.consts.RoundingRule rounding_rule = 11;
   */
  roundingRule = RoundingRule.UNKNOWN;

  /**
   * Independent amount rate
   *
   * @generated from field: aurora.core.common.protos.PbDecimal independent_amount_rate = 12;
   */
  independentAmountRate?: PbDecimal;

  /**
   * Aurora internal ID of the user who created the (partially) matched order
   *
   * @generated from field: string order_created_by = 13;
   */
  orderCreatedBy = "";

  /**
   * Collateral type
   *
   * @generated from field: aurora.core.apiengine.omsv1.CollateralType collateral_type = 14;
   */
  collateralType = CollateralType.UNSPECIFIED_COLLATERAL_TYPE;

  constructor(data?: PartialMessage<ExecutedOrderEvent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.ExecutedOrderEvent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "execution_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "order_details", kind: "message", T: OrderDetails },
    { no: 4, name: "exec_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "exec_rate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "execution_time", kind: "message", T: Timestamp },
    { no: 8, name: "counterparty_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "agreement_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "settlement_type", kind: "enum", T: proto3.getEnumType(SettlementType) },
    { no: 11, name: "rounding_rule", kind: "enum", T: proto3.getEnumType(RoundingRule) },
    { no: 12, name: "independent_amount_rate", kind: "message", T: PbDecimal },
    { no: 13, name: "order_created_by", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "collateral_type", kind: "enum", T: proto3.getEnumType(CollateralType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExecutedOrderEvent {
    return new ExecutedOrderEvent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExecutedOrderEvent {
    return new ExecutedOrderEvent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExecutedOrderEvent {
    return new ExecutedOrderEvent().fromJsonString(jsonString, options);
  }

  static equals(a: ExecutedOrderEvent | PlainMessage<ExecutedOrderEvent> | undefined, b: ExecutedOrderEvent | PlainMessage<ExecutedOrderEvent> | undefined): boolean {
    return proto3.util.equals(ExecutedOrderEvent, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.CanceledOrderEvent
 */
export class CanceledOrderEvent extends Message<CanceledOrderEvent> {
  /**
   * Unique request identifier assigned to the event
   *
   * @generated from field: string request_id = 1;
   */
  requestId = "";

  /**
   * Unique identifier of the canceled order
   *
   * @generated from field: string order_ref = 2;
   */
  orderRef = "";

  /**
   * Reason for cancellation
   *
   * @generated from field: string reason = 3;
   */
  reason = "";

  /**
   * Details of the order
   *
   * @generated from field: aurora.core.apiengine.omsv1.OrderDetails order_details = 4;
   */
  orderDetails?: OrderDetails;

  constructor(data?: PartialMessage<CanceledOrderEvent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.CanceledOrderEvent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "order_details", kind: "message", T: OrderDetails },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CanceledOrderEvent {
    return new CanceledOrderEvent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CanceledOrderEvent {
    return new CanceledOrderEvent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CanceledOrderEvent {
    return new CanceledOrderEvent().fromJsonString(jsonString, options);
  }

  static equals(a: CanceledOrderEvent | PlainMessage<CanceledOrderEvent> | undefined, b: CanceledOrderEvent | PlainMessage<CanceledOrderEvent> | undefined): boolean {
    return proto3.util.equals(CanceledOrderEvent, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.ExpiredOrderEvent
 */
export class ExpiredOrderEvent extends Message<ExpiredOrderEvent> {
  /**
   * Unique request identifier assigned to the event
   *
   * @generated from field: string request_id = 1;
   */
  requestId = "";

  /**
   * Unique identifier of the expired order
   *
   * @generated from field: string order_ref = 2;
   */
  orderRef = "";

  /**
   * Details of the order
   *
   * @generated from field: aurora.core.apiengine.omsv1.OrderDetails order_details = 3;
   */
  orderDetails?: OrderDetails;

  constructor(data?: PartialMessage<ExpiredOrderEvent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.ExpiredOrderEvent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "order_details", kind: "message", T: OrderDetails },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExpiredOrderEvent {
    return new ExpiredOrderEvent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExpiredOrderEvent {
    return new ExpiredOrderEvent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExpiredOrderEvent {
    return new ExpiredOrderEvent().fromJsonString(jsonString, options);
  }

  static equals(a: ExpiredOrderEvent | PlainMessage<ExpiredOrderEvent> | undefined, b: ExpiredOrderEvent | PlainMessage<ExpiredOrderEvent> | undefined): boolean {
    return proto3.util.equals(ExpiredOrderEvent, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.ClosedOrderEvent
 */
export class ClosedOrderEvent extends Message<ClosedOrderEvent> {
  /**
   * Unique request identifier assigned to the event
   *
   * @generated from field: string request_id = 1;
   */
  requestId = "";

  /**
   * Unique identifier of the closed order
   *
   * @generated from field: string order_ref = 2;
   */
  orderRef = "";

  /**
   * Details of the order
   *
   * @generated from field: aurora.core.apiengine.omsv1.OrderDetails order_details = 3;
   */
  orderDetails?: OrderDetails;

  constructor(data?: PartialMessage<ClosedOrderEvent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.ClosedOrderEvent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "order_details", kind: "message", T: OrderDetails },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ClosedOrderEvent {
    return new ClosedOrderEvent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ClosedOrderEvent {
    return new ClosedOrderEvent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ClosedOrderEvent {
    return new ClosedOrderEvent().fromJsonString(jsonString, options);
  }

  static equals(a: ClosedOrderEvent | PlainMessage<ClosedOrderEvent> | undefined, b: ClosedOrderEvent | PlainMessage<ClosedOrderEvent> | undefined): boolean {
    return proto3.util.equals(ClosedOrderEvent, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.QueryExecutionRecordsRequest
 */
export class QueryExecutionRecordsRequest extends Message<QueryExecutionRecordsRequest> {
  /**
   * New unique identifier for the request, assigned by the user
   *
   * @generated from field: string request_id = 1;
   */
  requestId = "";

  /**
   * The company whose execution records are being requested
   *
   * @generated from field: string company_id = 2;
   */
  companyId = "";

  /**
   * Number of execution records per page; at most this many records will be returned
   *
   * @generated from field: int32 page_limit = 3;
   */
  pageLimit = 0;

  /**
   * Number of the page to be returned considering the page_size
   *
   * @generated from field: int32 page_number = 4;
   */
  pageNumber = 0;

  /**
   * Filtering options
   *
   * @generated from field: optional aurora.core.apiengine.omsv1.QueryExecutionRecordsFilter filter = 5;
   */
  filter?: QueryExecutionRecordsFilter;

  /**
   * List of sorting options
   *
   * @generated from field: repeated aurora.core.apiengine.omsv1.QueryExecutionRecordsSorting sorts = 6;
   */
  sorts: QueryExecutionRecordsSorting[] = [];

  constructor(data?: PartialMessage<QueryExecutionRecordsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.QueryExecutionRecordsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "page_limit", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "filter", kind: "message", T: QueryExecutionRecordsFilter, opt: true },
    { no: 6, name: "sorts", kind: "message", T: QueryExecutionRecordsSorting, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryExecutionRecordsRequest {
    return new QueryExecutionRecordsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryExecutionRecordsRequest {
    return new QueryExecutionRecordsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryExecutionRecordsRequest {
    return new QueryExecutionRecordsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: QueryExecutionRecordsRequest | PlainMessage<QueryExecutionRecordsRequest> | undefined, b: QueryExecutionRecordsRequest | PlainMessage<QueryExecutionRecordsRequest> | undefined): boolean {
    return proto3.util.equals(QueryExecutionRecordsRequest, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.QueryExecutionRecordsResponse
 */
export class QueryExecutionRecordsResponse extends Message<QueryExecutionRecordsResponse> {
  /**
   * The request_id provided in the original request
   *
   * @generated from field: string request_id = 1;
   */
  requestId = "";

  /**
   * Total number of Execution Records returned
   *
   * @generated from field: int64 total_count = 2;
   */
  totalCount = protoInt64.zero;

  /**
   * The list of Execution Records that the query returned
   *
   * @generated from field: repeated aurora.core.apiengine.omsv1.ExecutionRecord exec_recs = 3;
   */
  execRecs: ExecutionRecord[] = [];

  /**
   * Companies referenced in the Execution Records
   *
   * @generated from field: map<string, aurora.core.common.protos.PbCompanyInfo> companies = 4;
   */
  companies: { [key: string]: PbCompanyInfo } = {};

  /**
   * Users referenced in the Execution Records
   *
   * @generated from field: map<string, aurora.core.common.protos.PbUserInfo> users = 5;
   */
  users: { [key: string]: PbUserInfo } = {};

  /**
   * Instruments referenced in the Execution Records
   *
   * @generated from field: map<string, aurora.core.common.protos.PbInstrumentInfo> instruments = 6;
   */
  instruments: { [key: string]: PbInstrumentInfo } = {};

  /**
   * Agreements referenced in the Execution Records
   *
   * @generated from field: map<string, aurora.core.common.protos.PbAgreementInfo> agreements = 7;
   */
  agreements: { [key: string]: PbAgreementInfo } = {};

  constructor(data?: PartialMessage<QueryExecutionRecordsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.QueryExecutionRecordsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "total_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "exec_recs", kind: "message", T: ExecutionRecord, repeated: true },
    { no: 4, name: "companies", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: PbCompanyInfo} },
    { no: 5, name: "users", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: PbUserInfo} },
    { no: 6, name: "instruments", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: PbInstrumentInfo} },
    { no: 7, name: "agreements", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: PbAgreementInfo} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryExecutionRecordsResponse {
    return new QueryExecutionRecordsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryExecutionRecordsResponse {
    return new QueryExecutionRecordsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryExecutionRecordsResponse {
    return new QueryExecutionRecordsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: QueryExecutionRecordsResponse | PlainMessage<QueryExecutionRecordsResponse> | undefined, b: QueryExecutionRecordsResponse | PlainMessage<QueryExecutionRecordsResponse> | undefined): boolean {
    return proto3.util.equals(QueryExecutionRecordsResponse, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.QueryExecutionRecordsFilter
 */
export class QueryExecutionRecordsFilter extends Message<QueryExecutionRecordsFilter> {
  /**
   * CUSIP or ticker of instrument
   *
   * @generated from field: repeated string instruments = 1;
   */
  instruments: string[] = [];

  constructor(data?: PartialMessage<QueryExecutionRecordsFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.QueryExecutionRecordsFilter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "instruments", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryExecutionRecordsFilter {
    return new QueryExecutionRecordsFilter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryExecutionRecordsFilter {
    return new QueryExecutionRecordsFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryExecutionRecordsFilter {
    return new QueryExecutionRecordsFilter().fromJsonString(jsonString, options);
  }

  static equals(a: QueryExecutionRecordsFilter | PlainMessage<QueryExecutionRecordsFilter> | undefined, b: QueryExecutionRecordsFilter | PlainMessage<QueryExecutionRecordsFilter> | undefined): boolean {
    return proto3.util.equals(QueryExecutionRecordsFilter, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.QueryExecutionRecordsSorting
 */
export class QueryExecutionRecordsSorting extends Message<QueryExecutionRecordsSorting> {
  /**
   * Field to sort by
   *
   * @generated from field: aurora.core.apiengine.omsv1.QueryExecutionRecordsSortableField field = 1;
   */
  field = QueryExecutionRecordsSortableField.UNSPECIFIED_QUERY_EXECUTION_RECORDS_SORTABLE_FIELD;

  /**
   * Sorting order
   *
   * @generated from field: aurora.core.consts.query.SortOrder order = 2;
   */
  order = SortOrder.UNSPECIFIED_SORT_ORDER;

  constructor(data?: PartialMessage<QueryExecutionRecordsSorting>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.QueryExecutionRecordsSorting";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "field", kind: "enum", T: proto3.getEnumType(QueryExecutionRecordsSortableField) },
    { no: 2, name: "order", kind: "enum", T: proto3.getEnumType(SortOrder) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryExecutionRecordsSorting {
    return new QueryExecutionRecordsSorting().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryExecutionRecordsSorting {
    return new QueryExecutionRecordsSorting().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryExecutionRecordsSorting {
    return new QueryExecutionRecordsSorting().fromJsonString(jsonString, options);
  }

  static equals(a: QueryExecutionRecordsSorting | PlainMessage<QueryExecutionRecordsSorting> | undefined, b: QueryExecutionRecordsSorting | PlainMessage<QueryExecutionRecordsSorting> | undefined): boolean {
    return proto3.util.equals(QueryExecutionRecordsSorting, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.ExecutionRecord
 */
export class ExecutionRecord extends Message<ExecutionRecord> {
  /**
   * Execution ID
   *
   * @generated from field: string exec_id = 1;
   */
  execId = "";

  /**
   * Execution time
   *
   * @generated from field: google.protobuf.Timestamp exec_time = 2;
   */
  execTime?: Timestamp;

  /**
   * Aurora Order Display ID of the order that was (partially) executed
   *
   * @generated from field: string order_display_id = 3;
   */
  orderDisplayId = "";

  /**
   * User-defined external ID of the order that was (partially) executed
   *
   * @generated from field: string client_order_ref = 4;
   */
  clientOrderRef = "";

  /**
   * The CUSIP of the instrument in the order
   *
   * @generated from field: string cusip = 5;
   */
  cusip = "";

  /**
   * The side of the order
   *
   * @generated from field: aurora.core.consts.common.Side side = 6;
   */
  side = Side.BORROWER;

  /**
   * Owner of the order
   *
   * @generated from field: string company_id = 24;
   */
  companyId = "";

  /**
   * Aurora internal ID for the counterparty company
   *
   * @generated from field: string counterparty_id = 7;
   */
  counterpartyId = "";

  /**
   * Aurora internal ID for the agreement related to the (partially) executed order
   *
   * @generated from field: string agreement_id = 8;
   */
  agreementId = "";

  /**
   * Executed quantity
   *
   * @generated from field: int64 exec_qty = 9;
   */
  execQty = protoInt64.zero;

  /**
   * Unit price at time of execution
   *
   * @generated from field: string price = 10;
   */
  price = "";

  /**
   * Details of the rate
   *
   * @generated from field: aurora.core.common.protos.PbRateDetails rate_details = 11;
   */
  rateDetails?: PbRateDetails;

  /**
   * Settlement amount
   *
   * @generated from field: string settlement_amount = 12;
   */
  settlementAmount = "";

  /**
   * Settlement type
   *
   * @generated from field: aurora.core.consts.settlement.SettlementType settlement_type = 13;
   */
  settlementType = SettlementType.UNSPECIFIED;

  /**
   * Independent amount rate
   *
   * @generated from field: string independent_amount_rate = 14;
   */
  independentAmountRate = "";

  /**
   * Rounding rule
   *
   * @generated from field: aurora.core.consts.RoundingRule rounding_rule = 15;
   */
  roundingRule = RoundingRule.UNKNOWN;

  /**
   * Collateral type
   *
   * @generated from field: aurora.core.apiengine.omsv1.CollateralType collateral_type = 16;
   */
  collateralType = CollateralType.UNSPECIFIED_COLLATERAL_TYPE;

  /**
   * Trade date
   *
   * @generated from field: aurora.core.common.protos.PbDate trade_date = 17;
   */
  tradeDate?: PbDate;

  /**
   * Start date
   *
   * @generated from field: aurora.core.common.protos.PbDate start_date = 18;
   */
  startDate?: PbDate;

  /**
   * Aurora internal ID of the user who created the (partially) matched order
   *
   * @generated from field: string order_created_by = 19;
   */
  orderCreatedBy = "";

  /**
   * Status of the created loan, if managed by Aurora; otherwise empty
   *
   * @generated from field: optional int32 trade_status = 20;
   */
  tradeStatus?: number;

  /**
   * Aurora external ID of the loan, a.k.a. LoanDisplayID if managed by Aurora; otherwise empty
   *
   * @generated from field: optional string trade_id = 21;
   */
  tradeId?: string;

  /**
   * End date if managed by Aurora; otherwise empty
   *
   * @generated from field: optional aurora.core.common.protos.PbDate end_date = 22;
   */
  endDate?: PbDate;

  /**
   * Cash start date if managed by Aurora; otherwise empty
   *
   * @generated from field: optional aurora.core.common.protos.PbDate cash_start_date = 23;
   */
  cashStartDate?: PbDate;

  constructor(data?: PartialMessage<ExecutionRecord>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.ExecutionRecord";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "exec_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "exec_time", kind: "message", T: Timestamp },
    { no: 3, name: "order_display_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "client_order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "cusip", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "side", kind: "enum", T: proto3.getEnumType(Side) },
    { no: 24, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "counterparty_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "agreement_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "exec_qty", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "price", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "rate_details", kind: "message", T: PbRateDetails },
    { no: 12, name: "settlement_amount", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "settlement_type", kind: "enum", T: proto3.getEnumType(SettlementType) },
    { no: 14, name: "independent_amount_rate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "rounding_rule", kind: "enum", T: proto3.getEnumType(RoundingRule) },
    { no: 16, name: "collateral_type", kind: "enum", T: proto3.getEnumType(CollateralType) },
    { no: 17, name: "trade_date", kind: "message", T: PbDate },
    { no: 18, name: "start_date", kind: "message", T: PbDate },
    { no: 19, name: "order_created_by", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "trade_status", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 21, name: "trade_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 22, name: "end_date", kind: "message", T: PbDate, opt: true },
    { no: 23, name: "cash_start_date", kind: "message", T: PbDate, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExecutionRecord {
    return new ExecutionRecord().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExecutionRecord {
    return new ExecutionRecord().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExecutionRecord {
    return new ExecutionRecord().fromJsonString(jsonString, options);
  }

  static equals(a: ExecutionRecord | PlainMessage<ExecutionRecord> | undefined, b: ExecutionRecord | PlainMessage<ExecutionRecord> | undefined): boolean {
    return proto3.util.equals(ExecutionRecord, a, b);
  }
}

